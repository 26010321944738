import React, {useEffect, useRef} from 'react';

const VideoContainer = ({user}) => {
    const video = useRef(null);

    useEffect(() => {
        if (user.stream && video) {
            video.current.srcObject = user.stream;
            video.current.onclick = () => false;
        }
    }, [user.stream]);

    return (
        <div className='participant' id={user.participantId}>
            <video
                id={`native-video-${user.participantId}`}
                ref={video}
                muted={true}
                playsInline
                pip={"true"}
                autoPlay
                disablePictureInPicture
            />
            <p className='user-name'>{user.participantName}</p>
        </div>
    );
};

export default VideoContainer;
