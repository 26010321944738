import React, {useState} from 'react';
import UserInputs from "./UserInputs";
import VideoGrid from "./VideoGrid";
import VideoControls from "./VideoControls";
import {useListeners} from "../hooks/useListeners";

const Session = ({ qrData, clearQrData }) => {

    const participants = useListeners();
    const [qualityDialog, controlQuality] = useState(false)


    return (
        <div className={'session-container'}>

            <div className={participants.length > 0 ? 'sceenic-logo': 'empty-logo'}
            onClick={() => {
                if(participants.length > 0)
                    controlQuality(!qualityDialog)
            }
            }
            />
            <VideoGrid participants={participants}/>
            <div className={'control-container'}>
                {participants.length ? <VideoControls/> : null}
                <UserInputs clearQrData={clearQrData} participant={participants[0]} qrData={qrData} qualityDialog={qualityDialog} qualityControl={() => controlQuality(false)}/>
            </div>
        </div>
    );
};

export default Session;
