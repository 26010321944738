import React, {useEffect, useState} from "react";
import Session from "./components/Session";
import QrScan from 'react-qr-reader'
import './styles/App.css'


function App() {

    const [data, setData] = useState(false)
    const handleScan = data => {
        if (data) {
            setData(data)
        }
    }
    const handleError = err => {
        console.error(err)
    }



    return <div className="App">
        {!data &&    <div className={'qr-container'}>
            <div className={'qr-title'}>Scan QR on TV</div>
            <QrScan
                scanDelay={300}
                className={'scanner-container'}
                delay={300}
                onError={handleError}
                onScan={handleScan}
            />
        </div>}

            {data && <Session className='Session' qrData={data} clearQrData={() => {
            setData(false)
            }
            } />}
        </div>
}

export default App;