import React, {useEffect, useState} from 'react';
import WT from "@sscale/wtsdk";
import {authRequest} from "./API/TokenService";
import {useStorage} from "../hooks/useStorage";
import CustomInput from "./UI/input/customInput";
import CustomButton from "./UI/button/customButton";

const UserInputs = ({ qualityDialog, qualityControl, qrData, participant, clearQrData }) => {

    const defaultConnectionParams = {
        authUrl: "https://auth.s1.sceenic.co/token/v2/",
        authToken: "rqouSTUwTrmNVCQK8Lt/lw==",
        sessionId: 'comcast_demo_room',
        streamingToken: "",
        participantName: ""
    }


    const [connectionParams, setConnectionParams] = useState({...defaultConnectionParams})
    const [connected, setConnected ] = useState(false)
    const [width, setWidth] = useState(720);
    const [height, setHeight] = useState(480);
    const [frameRate, setRate] = useState(15);
    useStorage(setConnectionParams);

    useEffect(() => {
        if(participant){
            const message = {
                    qrData: qrData,
                    participantId: participant.participantId
            }
            WT.Session.sendMessage(JSON.stringify(message))
        }
    }, [participant])

    const onConnect = async (event) => {
        event.preventDefault();
            const {streaming_token = '' } = await authRequest(
                connectionParams.authUrl,
                connectionParams.authToken,
                connectionParams.sessionId
            );
            console.log("xAuth token", streaming_token)
            streaming_token
                ? WT.Session.connect(streaming_token, connectionParams.participantName, {audio: true, video: true}, {networkTopology: 'media-server', maxBandwidth: 1000000})
                : alert("Unable to get token from xAuth!")

        setConnected(true)

    }

    const onDisconnect = (event) => {
        event.preventDefault();
        WT.Session.disconnect();
        setConnected(false)
        clearQrData()
    }
    const setMediaQuality = (width, height, frames) => {
        WT.Participant.setMediaQuality({videoWidth: width, videoHeight: height, frameRate: frames})
        qualityControl()
    }

    const onChangeParticipantName = (event) => {
        setConnectionParams({...connectionParams, participantName: event.target.value})
    }

    return (
        <div className={'input-content-container'}>
            { qualityDialog && <div className={'quality-dialog'}>
                Width: <CustomInput
                value={width}
                onChange={(input) => {setWidth(input.target.value)}}></CustomInput>
                Height: <CustomInput
                value={height}
                onChange={(input) => setHeight(input.target.value) }
            ></CustomInput>
                FrameRate: <CustomInput
            value={frameRate}
            onChange={(input) => setRate(input.target.value) }
            ></CustomInput>
                <CustomButton onClick={() => {
                    setMediaQuality(width, height, frameRate)
                }}>Set</CustomButton>
            </div>
            }
            {!connected && <CustomInput
                id="participant"
                type="text"
                placeholder="Enter your namee"
                value={connectionParams.participantName}
                onChange={onChangeParticipantName}
                withLabel={false}
            />}
            <div className="buttons">
                {!connected && <CustomButton
                    // disabled={!connected}
                    className={'connect-button'}
                    onClick={onConnect}>
                    JOIN
                </CustomButton>}
                </div>
            <div className={'buttons-dis'}>
                {connected && <CustomButton
                    className={'disconnect-button'}
                    // disabled={!connected}
                    onClick={onDisconnect}>
                </CustomButton>}
            </div>
        </div>
    );
};

export default UserInputs;
